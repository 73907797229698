<template>
  <b-sidebar 
    id="manage-guides-sidebar" 
    no-header 
    right 
    shadow 
    backdrop
    @shown="getGuideResume"
  >
    <template #default="{ hide }">
      <div class="container-wrapper">
        <section class="header">
          <div class="manage-guide-title">Resumo do lote</div>
          <div class="actions">
            <Close class="close" @click="hide" />
          </div>
        </section>
        <section class="guides-summary">
          <dl v-if="resume">
            <div class="guides-summary-row">
              <dt>Lote Nº:</dt>
              <dd>{{ resume?.tiss_batch?.number }}</dd>
              <dt>Fatura Nº:</dt>
              <dd>{{ resume?.tiss_invoice?.number }}</dd>
            </div>
            <div class="guides-summary-row">
              <dt>Envio:</dt>
              <dd>
                <span>{{ resume?.tiss_batch?.send_date ? moment(resume.tiss_batch.send_date).format('DD/MM/YYYY') : '-' }}</span>
              </dd>
              <dt>Tipo:</dt>
              <dd>{{ parseGuideType(resume.guide_type) }}</dd>
            </div>
            <div class="guides-summary-row">
              <dt>Pagamento</dt>
              <dd>
                <span>{{ resume?.tiss_batch?.payment_date ? moment(resume.tiss_batch.payment_date).format('DD/MM/YYYY') : '-' }}</span>
              </dd>
              <dt>Versão TISS:</dt>
              <dd>{{ resume?.tiss_batch?.xml_version }}</dd>
            </div>
            <div class="guides-summary-row">
              <dt>Nº protocolo:</dt>
              <dd>{{ resume?.tiss_batch?.protocol_number || '-' }}</dd>
            </div>
          </dl>
          <div class="skeleton-wrapper" v-else>
            <b-skeleton width="100%" />
            <b-skeleton width="100%" />
            <b-skeleton width="100%" />
            <b-skeleton width="100%" />
          </div>
        </section>

        <section class="guides-summary-cards">
            <SimpleCard
              v-if="resume"
              :isMoney="false"
              title="Tipo de guia"
              :value="parseGuideType(resume.guide_type)"
              color="#304388"
            />
            <b-skeleton v-else width="100%" height="100px"/>
            <SimpleCard
              v-if="resume"
              :isMoney="true"
              title="Valor faturado"
              :value="resume.items_total"
              color="#0C1D59"
            />
            <b-skeleton v-else width="100%" height="100px"/>
        </section>
        <section class="guides-summary-cards-3">
          <SimpleCard
            v-if="resume"
            :isMoney="false"
            title="Pago parcial"
            :value="resume.partially_paid_items_count || 0"
            color="#FF6B00"
          />
          <b-skeleton v-else width="100%" height="100px"/>

          <SimpleCard
            v-if="resume"
            :isMoney="false"
            title="Glosado"
            :value="resume.glossed_items_count"
            color="#F63220"
          />
          <b-skeleton v-else width="100%" height="100px"/>

          <SimpleCard
            v-if="resume"
            :isMoney="false"
            title="Pagos"
            :value="resume.payed_items_count"
            color="#069763"
          />
          <b-skeleton v-else width="100%" height="100px"/>

        </section>
        <section class="guides-summary-cards">
          <SimpleCard
            v-if="resume"
            :isMoney="true"
            title="Valor Glosado"
            :value="resume.items_glossed"
            color="#F63220"
          />
          <b-skeleton v-else width="100%" height="100px"/>

          <SimpleCard
            v-if="resume"
            :isMoney="true"
            title="Valor a receber"
            :value="resume.items_paid"
            color="#069763"
          />
          <b-skeleton v-else width="100%" height="100px"/>
        </section>

        <hr />

        <section class="guides-summary-items">
          <b-card v-if="resume">
            <strong>Quantidade de procedimentos: </strong>
            <span>{{ resume.procedures_count }} procedimento{{resume.procedures_count > 1 ? 's' : ''}}</span>
          </b-card>
          <b-skeleton v-else width="100%" height="41px"/>

          <b-card v-if="resume">
            <strong>Quantidade de produtos: </strong>
            <span>{{ resume.products_count }} produto{{resume.products_count > 1 ? 's' : ''}}</span>
          </b-card>
          <b-skeleton v-else width="100%" height="41px"/>

          <b-card v-if="resume">
            <strong>Quantidade de despesas: </strong>
            <span>{{ resume.medicines_count }} despesa{{resume.medicines_count > 1 ? 's' : ''}}</span>
          </b-card>
          <b-skeleton v-else width="100%" height="41px"/>
        </section>


        <div class="wrapper-button">
          <b-dropdown text="Gerenciar todas as guias" variant="primary" menu-class="w-100" dropup block>
            <b-dropdown-item-button v-for="(option, index) in statusOptions" :key="index" @click="onChangeStatuses(option.value)">
              {{ option.label }}
            </b-dropdown-item-button>
          </b-dropdown>
          <b-button
            variant="primary"
            block
            @click="bulkUpdateTissGuides"
          >
            Salvar guia
          </b-button>
          <b-button
            variant="outline-primary"
            block
            @click="hide"
          >
            Ocultar resumo da guia
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import api from '@/modules/tiss/manageGuides/api'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { parseGuideType } from '@/modules/tiss/manageGuides/utils/guideHelper'
import { guideStatusOptions } from '@/modules/tiss/manageGuides/utils/statuses'

export default {
  name: 'ManageGuidesSidebar',
  components: {
    SimpleCard: () => import('@/components/Dashboard/SimpleCard'),
    Close: () => import('@/assets/icons/close.svg')
  },
  data() {
    return {
      statusOptions: guideStatusOptions,
      loading: false,
      clinic: getCurrentClinic(),
      resume: null,
    }
  },
  async mounted() {},
  methods: {
    parseGuideType,
    async getGuideResume() {
      this.loading = true
      try {
        const { data } = await api.getGuideResume(this.$route.params.guideId)
        this.resume = data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async onChangeStatuses(status) {
      const res = await this.$swal({
        title: 'Gerenciar situações',
        html: `<div class="body-alert">Deseja realmente alterar a situação 
            de todas as guias para <span>"${status}"</span>?</div>`,
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#305BF2',
        cancelButtonText: 'Cancelar',
        customClass: {
          cancelButton: 'btn-cancel',
          confirmButton: 'btn-primary'
        },
      })

      if (res.isConfirmed) {
        this.$emit('update', status)
      }
    },
    async bulkUpdateTissGuides() {
      this.$emit('save')
      this.$root.$emit('bv::toggle::collapse', 'manage-guides-sidebar')
    }
  }
}
</script>

<style lang="scss">
#manage-guides-sidebar {
  min-width: 500px;

  .title {
    display: flex;
    flex-direction: row;

    h4 {
      color: var(--dark-blue);
      font-weight: 700;
      font-size: 18px;
      margin-right: 20px;
    }

    h5 {
      color: var(--blue-600);
      font-weight: 600;
      font-size: 18px;

      span {
        color: var(--neutral-500);
      }
    }
  }

  .guides-summary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
    padding: 20px;
    border-radius: 8px;
    border: 1px dashed var(--neutral-300);
    background: var(--neutral-100);

    .guides-summary-row {
      display: grid;
      grid-template-columns: auto 1fr auto 3fr;
      gap: 10px;
    }

    dt {
      color: var(--neutral-700);
    }

    dd {
      margin: 0;
    }
  }

  .guides-summary-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin: 10px 0;
  }

  .guides-summary-cards-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 10px 0;
  }

  .box1 {
    grid-row-start: 1;
    grid-row-start: 3;
  }

  .card {
      box-shadow: 0px 2px 1px 0px rgba(12, 29, 89, 0.04);

      .value {
        font-size: 25px !important;
      }
    }



  .guides-summary-items {
    .card {
      margin: 10px 0;
      font-size: 14px;
      border-radius: 8px;
      border: 1px solid var(--neutral-500);
      
      .card-body {
        padding: 10px;
      }

      strong {
        color: var(--dark-blue);
      }

      span {
        font-weight: 600;
        color: var(--blue-600);
      }
    }
  }

  .guides-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .guide-container {
      margin: 10px 0;
    }
  }

  .container-wrapper {
    padding: 0 1rem;
  }

  .header {
    display: flex;
    width: 100%;
    padding: 1rem 0;
    justify-content: space-between;
    align-items: center;
  }

  .manage-guide-title {
    font-family: 'Red Hat Display';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    color: var(--dark-blue);
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .close {
    width: 25px;
    fill: var(--neutral-500);
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    flex-direction: column;
    gap: 10px;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
    }

    .btn-primary {
      color: var(--neutral-000);
    }

    .btn-outline-primary {
      color: var(--blue-500);
    }

    .dropdown-item {
      font-weight: 400;
    }
  }




  .skeleton-wrapper {
    width: 100%;
  }
}

.swal2-container {
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;

  .swal2-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }

  .swal2-html-container {
    margin: 0 20px;
    padding: 20px 0;
    text-align: left;
    font-weight: 600;
    border-bottom: 1px solid var(--neutral-200);
  }

  .swal2-actions {
    justify-content: flex-end;
    margin-right: 10px;

    .btn-primary {
      border-radius: 8px;
    }

    .btn-cancel {
      border: none;
      background: none;
      color: #F63220;
    }
  }
}
</style>